import React, { Fragment } from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { renderAst } from "../components/ast/renderAst"
import ContactForm from "../components/contact/contactForm"
import ContentAst from "../components/content/contentAst"

export default ({
  data: {
    contentfulLayoutTemplate: {
      title,
      shortDescription,
      slug,
      canonical,
      blocks,
    },
  },
  location,
}) => {
  return (
    <Layout location={location} pageTitle={title}>
      <SEO
        title="Contact"
        description={shortDescription}
        pathname={slug}
        canonical={canonical}
      />
      <div className="container mx-auto px-6">
        <h1 className="text-4xl font-bold mt-6 mb-10">{title}</h1>
        <div className="flex items-stretch flex-wrap lg:flex-row-reverse">
          <div className="flex items-stretch flex-1 w-full lg:pl-6 lg:w-1/2">
            <div className="flex items-stretch flex-1 mb-10 lg:mb-0">
              <ContactForm location={location} pageTitle={title} />
              {/* <ContactHubspotForm /> */}
            </div>
          </div>
          <div className="w-full lg:pr-6 lg:w-1/2">
            {blocks !== null &&
              blocks.map((block) => {
                return (
                  <Fragment key={block.id}>
                    {block.__typename === "ContentfulBlock" && (
                      <div className="prose prose-lg max-w-none">
                        {renderAst(
                          ContentAst,
                          block.components[0].text.childMarkdownRemark.htmlAst
                        )}
                      </div>
                    )}
                  </Fragment>
                )
              })}
          </div>
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query contactPageQuery {
    contentfulLayoutTemplate(title: { eq: "Contact" }) {
      id
      title
      slug
      canonical
      seoTitle
      shortDescription
      type
      image {
        id
        description
        fluid(maxWidth: 580) {
          base64
          aspectRatio
          src
          srcSet
          srcWebp
          srcSetWebp
          sizes
        }
      }
      blocks {
        __typename
        id
        title
        components {
          ... on Node {
            __typename
            id
            ... on ContentfulContentComponent {
              id
              title
              text {
                childMarkdownRemark {
                  htmlAst
                }
              }
            }
          }
        }
      }
    }
  }
`
