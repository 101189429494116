import React, { useState } from "react"
import queryString from "query-string"
import { useForm } from "react-hook-form"
import { GoogleReCaptcha } from "react-google-recaptcha-v3"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSpinner } from "@fortawesome/free-solid-svg-icons"

import ErrorIcon from "../errorIcon"

const ContactForm = ({ location, pageTitle }) => {
  const [submitted, setSubmitted] = useState(false)
  const [nameSubmitted, setNameSubmitted] = useState("")
  const {
    register,
    handleSubmit,
    errors,
    setError,
    formState: { isSubmitting },
    reset,
  } = useForm({ mode: "onBlur" })
  const [recaptchaToken, setRecaptchaToken] = useState()
  const onSubmit = async (
    { firstName, lastName, email, message, botField },
    e
  ) => {
    e.preventDefault()
    if (recaptchaToken === undefined || recaptchaToken === "") {
      return setError("recaptcha", "invalid", "Google Recaptcha Error")
    }

    try {
      const response = await fetch(
        `/.netlify/functions/contact-form-submitted`,
        {
          method: "POST",
          header: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
          body: queryString.stringify({
            "form-name": e.target.getAttribute("name"),
            firstName,
            lastName,
            email,
            message,
            botField,
            recaptchaToken,
            hutk:
              document.cookie.replace(
                /(?:(?:^|.*;\s*)hubspotutk\s*=\s*([^;]*).*$)|^.*$/,
                "$1"
              ) || "",
            pageUri: location.href,
            pageName: pageTitle,
          }),
        }
      )

      const data = await response.json()

      if (response.status === 422) {
        if (data.errors.recaptchaToken) {
          return setError("recaptcha", "invalid", "Google Recaptcha Error")
        } else if (data.errors.formName) {
          return setError("form-name", "invalid", "There was a form error")
        } else if (data.errors.botField) {
          return setError("botField", "invalid", "You are spam, stay away")
        } else {
          setError()
        }
        window.grecaptcha.reset()
        setSubmitted(false)
      }

      if (response.status === 200) {
        setNameSubmitted(data.firstName)
        reset()
        setSubmitted(true)
      }
    } catch (err) {
      console.error(`error: ${err}`)
      setError("submit", "submitError", err)
    }
  }

  return (
    <>
      {submitted ? (
        <p>
          {nameSubmitted.charAt(0).toUpperCase() + nameSubmitted.slice(1)},
          thanks for reaching out. We'll be in touch shortly!
        </p>
      ) : (
        <div className="relative p-2">
          {isSubmitting && (
            <div className="absolute inset-0 flex justify-center items-center z-50">
              <div className="absolute shadow inset-0 flex justify-center items-center bg-gray-300 rounded opacity-25" />
              <FontAwesomeIcon
                className="relative text-4xl text-brand"
                icon={faSpinner}
                spin
              />
            </div>
          )}
          <form
            className="relative w-full mx-auto mb-6"
            onSubmit={handleSubmit(onSubmit)}
            name="Contact"
          >
            <div className="hidden">
              <label htmlFor="contact-form-bot-field">
                Don't fill this out:
                <input
                  id="contact-form-bot-field"
                  type="text"
                  name="botField"
                  ref={register}
                />
              </label>
            </div>

            <div
              className={`relative lg:flex mb-4 lg:mb-6 ${
                errors.firstName ? "lg:items-stretch" : "lg:items-center"
              }`}
            >
              <div className={`lg:w-1/3 ${errors.firstName && "lg:mt-2"}`}>
                <label
                  className="text-xl block text-gray-500 font-bold lg:text-right mb-1 lg:mb-0 pr-4"
                  htmlFor="firstName"
                >
                  First Name
                </label>
              </div>
              <div className="lg:w-2/3">
                <div className="relative">
                  <input
                    id="firstName"
                    type="text"
                    name="firstName"
                    className={`text-xl bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-brand${
                      errors.firstName
                        ? " border-red-300 text-red-900 placeholder-red-300 focus:border-red-300 focus:shadow-outline-red"
                        : ""
                    }`}
                    ref={register({
                      required: true,
                      minLength: 2,
                      maxlength: 50,
                    })}
                  />
                  {errors.firstName && <ErrorIcon />}
                </div>
                {errors.firstName && (
                  <p className="mt-2 text-xs text-red-600">
                    First Name is required.
                  </p>
                )}
              </div>
            </div>

            <div
              className={`relative lg:flex mb-4 lg:mb-6 ${
                errors.lastName ? "lg:items-stretch" : "lg:items-center"
              }`}
            >
              <div className={`lg:w-1/3 ${errors.lastName && "lg:mt-2"}`}>
                <label
                  className="text-xl block text-gray-500 font-bold lg:text-right mb-1 lg:mb-0 pr-4"
                  htmlFor="lastName"
                >
                  Last Name
                </label>
              </div>
              <div className="lg:w-2/3">
                <div className="relative">
                  <input
                    id="lastName"
                    type="text"
                    name="lastName"
                    className={`text-xl bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-brand${
                      errors.lastName
                        ? " border-red-300 text-red-900 placeholder-red-300 focus:border-red-300 focus:shadow-outline-red"
                        : ""
                    }`}
                    ref={register({
                      required: true,
                      minLength: 2,
                      maxlength: 50,
                    })}
                  />
                  {errors.lastName && <ErrorIcon />}
                </div>
                {errors.lastName && (
                  <p className="mt-2 text-xs text-red-600">
                    Last Name is required.
                  </p>
                )}
              </div>
            </div>

            <div
              className={`relative lg:flex mb-4 lg:mb-6 ${
                errors.email ? "lg:items-stretch" : "lg:items-center"
              }`}
            >
              <div className={`lg:w-1/3 ${errors.email && "lg:mt-2"}`}>
                <label
                  className="text-xl block text-gray-500 font-bold lg:text-right mb-1 lg:mb-0 pr-4"
                  htmlFor="email"
                >
                  Email Address
                </label>
              </div>
              <div className="lg:w-2/3">
                <div className="relative">
                  <input
                    id="email"
                    type="email"
                    name="email"
                    className={`text-xl bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-brand${
                      errors.email
                        ? " border-red-300 text-red-900 placeholder-red-300 focus:border-red-300 focus:shadow-outline-red"
                        : ""
                    }`}
                    ref={register({
                      required: true,
                      pattern: /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                    })}
                  />
                  {errors.email && <ErrorIcon />}
                </div>
                {errors.email && (
                  <p className="mt-2 text-xs text-red-600">
                    Email is required.
                  </p>
                )}
              </div>
            </div>

            <div
              className={`relative lg:flex mb-4 lg:mb-6 ${
                errors.message ? "lg:items-stretch" : "lg:items-center"
              }`}
            >
              <div className={`lg:w-1/3 ${errors.message && "lg:mt-2"}`}>
                <label
                  className="text-xl block text-gray-500 font-bold lg:text-right mb-1 lg:mb-0 pr-4"
                  htmlFor="message"
                >
                  Message
                </label>
              </div>
              <div className="lg:w-2/3">
                <div className="relative">
                  <textarea
                    id="message"
                    name="message"
                    className={`text-xl resize-y bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-brand${
                      errors.message
                        ? " border-red-300 text-red-900 placeholder-red-300 focus:border-red-300 focus:shadow-outline-red"
                        : ""
                    }`}
                    ref={register({
                      required: true,
                      minLength: 2,
                      maxLength: 2000,
                    })}
                  />
                  {errors.message && <ErrorIcon />}
                </div>
                {errors.message && (
                  <p className="mt-2 text-xs text-red-600">
                    Message is required.
                  </p>
                )}
              </div>
            </div>

            <GoogleReCaptcha
              onVerify={(token) => {
                setRecaptchaToken(token)
              }}
            />

            <div className="lg:flex mb-6">
              <div className="lg:w-1/3"></div>
              <div className="lg:w-2/3 lg:flex lg:justify-center">
                {errors.recaptcha && (
                  <div className="flex mb-2">
                    <div className="inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                      <svg
                        className="h-5 w-5 text-red-500"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                      >
                        <path
                          fillRule="evenodd"
                          d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </div>
                    <span className="text-red-900">Google Recaptcha Error</span>
                  </div>
                )}
                {errors && errors.submit && (
                  <div className="flex justify-center mb-2">
                    <div className="inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                      <svg
                        className="h-5 w-5 text-red-500"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                      >
                        <path
                          fillRule="evenodd"
                          d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </div>
                    <span className="text-red-900">
                      {errors.submit.message}
                    </span>
                  </div>
                )}
              </div>
            </div>

            <div className="lg:flex mb-6">
              <div className="lg:w-1/3"></div>
              <div className="lg:w-2/3 lg:flex lg:justify-center">
                <button
                  type="submit"
                  className={`uppercase text-2xl font-bold text-white py-3 px-4 rounded-sm ${
                    isSubmitting
                      ? "cursor-not-allowed bg-gray-400"
                      : "bg-brand hover:bg-brand-lighter "
                  } `}
                  onClick={() => setSubmitted(false)}
                  disabled={isSubmitting}
                >
                  Submit
                </button>
              </div>
            </div>

            <small className="lg:flex">
              <div className="lg:w-1/3"></div>
              <div className="lg:w-2/3 lg:flex lg:justify-center">
                <div>
                  This form is protected by reCAPTCHA and the Google{" "}
                  <a
                    className="underline hover:opacity-75"
                    href="https://policies.google.com/privacy"
                    target="_blank"
                    rel="nofollow noopener noreferrer"
                  >
                    Privacy Policy
                  </a>{" "}
                  and{" "}
                  <a
                    className="underline hover:opacity-75"
                    href="https://policies.google.com/terms"
                    target="_blank"
                    rel="nofollow noopener noreferrer"
                  >
                    Terms of Service
                  </a>{" "}
                  apply.
                </div>
              </div>
            </small>
          </form>
        </div>
      )}
    </>
  )
}

export default ContactForm
